import classNames from "classnames";
import { StrapiProduct, StrapiStore } from "~/shared-types";
import { getRoute } from "~/utils/getRoute";
import { Card } from "../Card";
import { getOfferDetails } from "~/utils/getOfferDetails";
import Link from "next/link";
import { ProductCardImage } from "../ProductCardImage";
import { FavoriteButton } from "../FavoriteButton";
import { Badge } from "../Backroom/Badge";

type ProductCardProps = {
  product: StrapiProduct;
  store?: StrapiStore;
  className?: string;
  onImageLoad?: () => void;
};

export const ProductCard = ({
  product,
  store,
  className,
  onImageLoad,
}: ProductCardProps) => {
  const { name, price, images, subCategories, isUnique } = product.attributes;

  if (!images.data?.length || !subCategories?.data?.length) return null;

  const offerDetails = getOfferDetails(product);
  const vendor = product.attributes.store ?? { data: store };

  const linkToProduct = getRoute("/products/:productId", {
    productId: product.id,
  });

  return (
    <Card className={classNames("w-full", className)}>
      <ProductCardImage product={product} onImageLoad={onImageLoad} />

      <div className="flex justify-between items-center">
        <Card.Vendor store={vendor.data}></Card.Vendor>

        <FavoriteButton
          className="-mr-2 flex-shrink-0"
          favorite={{ id: product.id, type: "product" }}
        />
      </div>
      <Link href={linkToProduct}>
        <Card.Title>{name}</Card.Title>
        <div className="flex flex-col items-start gap-2">
          <Card.Price offerDetails={offerDetails} price={price} oneLine />
          {isUnique && <Badge color="unique">Unika</Badge>}
        </div>
      </Link>
    </Card>
  );
};
