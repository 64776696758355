import classNames from "classnames";
import Button from "../Button";
import Icon from "~/assets/icons/Icon";
import { useMemo, useState } from "react";
import { getIdsString } from "../ProductTags/getIdsString";
import { Text } from "../Text";
import { StrapiProduct } from "~/shared-types";

type PaginationNavigationProps = {
  className?: string;
};

// TODO: This should be a generic at some point
export const usePagination = (items: StrapiProduct[], pageSize = 8) => {
  const [pageIndex, setPageIndex] = useState(0);

  const onPageChange = (type: "left" | "right") => {
    if (type === "left" && pageIndex > 0) {
      setPageIndex((prev) => prev - 1);
    } else if (type === "right" && pageIndex < itemsByPage.length - 1) {
      setPageIndex((prev) => prev + 1);
    }
  };

  const itemsByPage = useMemo(() => {
    const pages = Math.ceil(items.length / pageSize);

    return Array.from({ length: pages }, (_, i) =>
      items.slice(i * pageSize, (i + 1) * pageSize)
    );
  }, [getIdsString(items)]);

  const currentItems = useMemo(() => {
    if (pageIndex > 0 && itemsByPage[pageIndex]) {
      return itemsByPage[pageIndex];
    }

    setPageIndex(0);
    return itemsByPage[0];
  }, [pageIndex, getIdsString(items)]);

  const PaginationNavigation = ({ className }: PaginationNavigationProps) => {
    if (itemsByPage.length <= 1) return null;

    return (
      <div
        className={classNames(
          className,
          "flex justify-center items-center gap-6"
        )}
      >
        <PaginationButton
          type="left"
          disabled={pageIndex === 0}
          onClick={() => onPageChange("left")}
        />

        <Text>
          Side {pageIndex + 1} / {itemsByPage.length}
        </Text>

        <PaginationButton
          type="right"
          disabled={pageIndex === itemsByPage.length - 1}
          onClick={() => onPageChange("right")}
        />
      </div>
    );
  };

  return {
    PaginationNavigation,
    currentItems,
    onPageChange,
    currentPage: pageIndex + 1,
    totalPages: itemsByPage.length,
  };
};

type PaginationButtonProps = {
  type: "left" | "right";
  disabled?: boolean;
  onClick?: () => void;
};
const PaginationButton = ({
  type,
  disabled,
  onClick,
}: PaginationButtonProps) => {
  return (
    <Button
      variant={disabled ? "disabled" : "secondary"}
      className={classNames(
        disabled && "cursor-not-allowed",
        "mx-1 shadow-md w-12 h-12 flex justify-center items-center rounded-full border-none"
      )}
      aria-label={type === "left" ? "Venstre pil" : "Højre pil"}
      onClick={onClick}
    >
      <Icon
        name="chevronRight"
        className={classNames("w-6 h-6", type === "left" && "rotate-180")}
      />
    </Button>
  );
};
