import Image, { ImageProps } from "next/image";
import {
  CardLocationProps,
  CardProps,
  CardTitleProps,
  CardVendorProps,
} from "./types";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import classNames from "classnames";
import Link from "next/link";
import Icon from "~/assets/icons/Icon";
import { HTMLLevels } from "../Heading";
import { OfferDetails } from "~/utils/getOfferDetails";
import { stringToCurrency } from "~/utils/stringConversion";
import { getRoute } from "~/utils/getRoute";
import { Badge } from "../Backroom/Badge";
import { Spinner } from "../Spinner";

function Card({ href, children, className }: CardProps) {
  const Tag = href ? Link : "div";
  return (
    // @ts-expect-error href is not a valid prop on div
    <Tag href={href || undefined} className={classNames("relative", className)}>
      <CompoundComponentContext.Provider value={"Card"}>
        {children}
      </CompoundComponentContext.Provider>
    </Tag>
  );
}
function CardImage({ className, onLoad, ...imageProps }: ImageProps) {
  useCompoundComponent("Card", "Image");
  return (
    <Image
      className={classNames("rounded-xl", className)}
      onLoad={onLoad}
      {...imageProps}
    />
  );
}
function Title({ level = HTMLLevels.h3, children }: CardTitleProps) {
  useCompoundComponent("Card", "Title");
  const Tag = level;
  return <Tag className="text-base mb-1.5 line-clamp-2">{children}</Tag>;
}
function Location({ children }: CardLocationProps) {
  useCompoundComponent("Card", "Location");
  return (
    <p className="text-xs mb-1.5 text-secondary-600 flex items-center gap-2">
      <Icon name="pinOutline" />
      {children}
    </p>
  );
}
function Vendor({ store }: CardVendorProps) {
  useCompoundComponent("Card", "Vendor");

  if (!store) return null;

  const href = getRoute("/stores/:id", { id: store.id });

  return (
    <Link href={href}>
      <p className="text-xs text-secondary-600">{store.attributes.name}</p>
    </Link>
  );
}
function Price({
  price,
  offerDetails,
  oneLine,
  isSoldOut,
}: {
  offerDetails: OfferDetails;
  price: number;
  oneLine?: boolean;
  isSoldOut?: boolean;
}) {
  useCompoundComponent("Card", "Price");

  const { hasCurrentOffer, offerPrice } = offerDetails;

  if (isSoldOut)
    return (
      <div
        className={classNames(
          "text-xs font-medium flex items-center gap-2",
          oneLine ? "flex-row" : "flex-col"
        )}
      >
        <span className="text-secondary-500 line-through">
          {stringToCurrency({ amount: price })}
        </span>
        <Badge color="redOutline">Solgt</Badge>
      </div>
    );

  if (!hasCurrentOffer)
    return (
      <p className="text-xs font-medium">
        {stringToCurrency({ amount: price })}
      </p>
    );

  return (
    <p
      className={classNames(
        "text-xs font-medium flex gap-1",
        oneLine ? "flex-row" : "flex-col"
      )}
    >
      <span className="text-secondary-500 line-through">
        {stringToCurrency({ amount: price })}
      </span>
      <span>{stringToCurrency({ amount: offerPrice })}</span>
    </p>
  );
}

Card.Image = CardImage;
Card.Title = Title;
Card.Location = Location;
Card.Vendor = Vendor;
Card.Price = Price;

const CardSkeleton = () => {
  return (
    <Card>
      <div className="bg-primary-50 aspect-[3/4] flex items-center justify-center">
        <Spinner />
      </div>
    </Card>
  );
};

export { Card, CardSkeleton };
