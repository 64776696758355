import { Favorite, useFavorites } from "~/atoms/favorites";
import Button from "../Button";
import Icon from "~/assets/icons/Icon";
import classNames from "classnames";

type Props = {
  favorite: Favorite;
  className?: string;
};

export const FavoriteButton = ({ favorite, className }: Props) => {
  const { toggleFavorite, findMatch } = useFavorites();

  const isFavorite = findMatch(favorite);

  return (
    <Button
      className={classNames("flex p-2 !shadow-none", className)}
      onClick={() => toggleFavorite(favorite)}
      title={isFavorite ? "Fjern fra favoritter" : "Tilføj til favoritter"}
    >
      {isFavorite ? (
        <Icon
          className={classNames("w-6 h-6 text-primary-500")}
          name="heartFilled"
          testId="heart-filled"
        />
      ) : (
        <Icon
          className={classNames("w-6 h-6")}
          name="heart"
          testId="heart-empty"
        />
      )}
    </Button>
  );
};
