import { useEffect, useState } from "react";

/**
 * Using modified hook since the one in usehooks-ts throws a hydration error (10/2023)
 *
 * Original usehooks-ts hook in link below
 * @see https://usehooks-ts.com/react-hook/use-media-query
 *
 * Also see this link for more information on the hydration problem
 * @see https://github.com/chakra-ui/chakra-ui/issues/3580
 *
 * @param query
 * @returns {boolean}
 */
export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const onMediaQueryChange = () => setMatches(!!mediaQueryList.matches);

    onMediaQueryChange();
    mediaQueryList.addEventListener("change", onMediaQueryChange);

    return () =>
      mediaQueryList.removeEventListener("change", onMediaQueryChange);
  }, [query]);

  return matches;
}
